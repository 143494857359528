import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Columns, Container, Heading, Level } from 'react-bulma-components';
import PresentationActionDropdown from './PresentationActionDropdown';
import { filter, find, forEach, isEmpty, map, pull, take } from 'lodash';
import { connectStats } from 'react-instantsearch-dom';
import DropdownMenu from '../../lib/DropdownMenu';
import Pluralize from 'pluralize';
import { Redirect } from 'react-router-dom';
import SourceTypeImage from '../SourceTypeImage';
import WithFilterListHeader from '../WithFilterListHeader';
import Constants from '../../Constants';
import { ReactComponent as Single } from '../../../svg/single.svg';
import AsyncLoadImage from '../AsyncLoadImage';
import { ContentNameCell, ListShareCellFactory } from '../FullPageListCells';
import pdf from '../../../lib/pdf';
import PdfDownloadButton from '../../consumer/PdfDownloadButton';
import { UserContext } from '../../UserContext';
import AccessModal from '../AccessModal';
import AccessManager from '../../../lib/AccessManager';
import localeDateFormatterUtils from '../../../lib/localeDate';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import API from '../../../lib/api';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import EmailStatusCell from 'components/shared/presentations/EmailStatusCell';
import FullPageListWithBulkActions from 'components/producer/bulkAction/FullPageListWithBulkActions';
import BulkDeleteButton from 'components/producer/dynamicContent/BulkDeleteButton';
import Banner, { toastBanner } from '../Banner';
import Icon from '../../lib/Icon';
import { useBulkTemplateThumbnails } from 'lib/hooks/useTemplate';
import UserAvatar from 'react-user-avatar';
import Tag from '../../lib/Tag';
import { usePresentations } from 'lib/hooks/usePresentation';
import { createColumnHelper } from '@tanstack/react-table';
import useAccesses from 'lib/hooks/useAccess';

const ListWithFilterHeaderAndBulkActions = WithFilterListHeader(FullPageListWithBulkActions);

class PresentationsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToPresentationId: null,
      currentItem: {},
      dateFormat: Constants.DATE_FORMATS.usa,
      title: this.props.pagination.totalCount === 0 ? '' : `${this.props.pagination.totalCount} Generated`,
      users: null,
    };
  }

  componentDidMount() {
    API.get('/enterprises/users/', (response) => {
      this.setState({ users: response.data });
    });
    this.setState({
      dateFormat: localeDateFormatterUtils.getLocaleDateFormatStringFromLocale(
        localeDateFormatterUtils.getUserLocale(),
      ),
    });
  }

  render() {
    if (!this.props.initialPresentations || this.props.initialPresentations.length === 0) {
      return null;
    }

    if (this.state.redirectToPresentationId) {
      if (this.props.match.path === '/:tab') {
        return <Redirect push to={`/generated/${this.state.redirectToPresentationId}`} />;
      }
      return <Redirect push to={`/create/generated/${this.state.redirectToPresentationId}`} />;
    }

    const typeCell = (cellProps) => (
      <span className="is-flex" style={{ alignItems: 'center' }}>
        <SourceTypeImage width="40px" sourceType={cellProps.getValue()} />
      </span>
    );
    const dropdownTrigger = <Icon name="more" size={16} theme="regular" />;
    const actionsCell = (cellProps) => {
      const row = cellProps.row.original;
      const accessObj = new AccessManager(row.id, this.props.accessesByItemId, this.context.user);
      const canRead = accessObj.can('read');
      return (
        <span className="is-flex overflow-visible">
          <PresentationActionDropdown
            presentation={row}
            onPresentationDelete={this.props.onPresentationDelete}
            onPresentationRegenerate={this.props.onPresentationRegenerate}
            dropdownTrigger={dropdownTrigger}
            onRegenerateClick={this.props.onRegenerateClick}
            canRead={canRead}
          />
        </span>
      );
    };
    const slidesCell = (row) => {
      return <PresentationSlidesCell row={row} />;
    };
    const inputsCell = (cellProps) => {
      const row = cellProps.row.original;
      if (!row.params) {
        return null;
      }

      const params = row.params;
      const emptyParams = isEmpty(Object.keys(params));
      const cellContent = (
        <span data-testid="presentation-input-count">{!emptyParams ? Object.keys(params).length : 0}</span>
      );
      const paramsArr = map(params, (v, k) => {
        const param = find(this.props.inputs.param_keys, { name: k });
        let displayName = param?.display_name || k;
        return [displayName, v];
      });

      let overageTag = '';
      if (Object.keys(params).length > 5) {
        overageTag = <Tag rounded={true} label={`+${Object.keys(params).length - 5}`} />;
      }

      return (
        <span className="is-flex overflow-visible">
          <DropdownMenu dropdownTrigger={cellContent} isRight={false}>
            <Columns className="dropdown-columns pam">
              {!emptyParams ? (
                map(take(paramsArr, 5), (param) => (
                  <Tag key={param[0]} rounded={true} className="mbs mrs" label={`${param[0]}: ${param[1]}`} />
                ))
              ) : (
                <span className="dropdown-item">No Inputs</span>
              )}
              {overageTag}
            </Columns>
          </DropdownMenu>
        </span>
      );
    };

    const generatedCell = (cellProps) => {
      const momentDate = cellProps.getValue();
      if (!momentDate) {
        return null;
      }

      const cellContent = (
        <span>
          <Single className="mrs icon-pull-down" />
          {momentDate.fromNow()}
        </span>
      );

      return (
        <span className="is-flex overflow-visible">
          <DropdownMenu dropdownTrigger={cellContent} isRight={false} className="centered-text">
            <span className="dropdown-item">{momentDate.format(this.state.dateFormat)}</span>
          </DropdownMenu>
        </span>
      );
    };

    const statusCell = (cellProps) => {
      const presentation = cellProps.row.original;
      const warnings = filter(presentation.logs, { level: Constants.LOG_LEVEL.warning });
      const errors = filter(presentation.logs, { level: Constants.LOG_LEVEL.error });

      if (presentation.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
        return <EmailStatusCell presentation={presentation} warnings={warnings} errors={errors} leftMargin="mls" />;
      } else {
        return <PresentationStatusCell presentation={presentation} warnings={warnings} errors={errors} />;
      }
    };

    const generatedByCell = (cellProps) => {
      const creatorUserId = cellProps.getValue();
      const user = this.getUserById(creatorUserId);
      if (user) {
        return (
          <span data-tooltip-id="matik-tooltip" data-tooltip-content={user.name || ''}>
            {user?.name && (
              <div className="has-text-white">
                <UserAvatar colors={Constants.AVATAR_COLORS} size={32} name={user.name} src={user.photo_url} />
              </div>
            )}
          </span>
        );
      }
      return null;
    };

    const columnHelper = createColumnHelper();

    const columns = [
      columnHelper.accessor('presentation_type', {
        id: 'type',
        header: 'Type',
        cell: typeCell,
        meta: {
          width: '75px',
        },
      }),
      columnHelper.accessor('name', {
        header: 'Name',
        cell: (cellProps) =>
          ContentNameCell({ getValue: () => cellProps.getValue() || cellProps.row.original.template?.name }),
      }),
      columnHelper.accessor((row) => row, {
        id: 'slideCount',
        header: 'Slides',
        cell: (cellProps) => slidesCell({ original: cellProps.getValue() }),
        meta: {
          width: '90px',
          tdStyles: {
            overflow: 'visible',
          },
        },
      }),
      columnHelper.accessor(
        (row) => {
          return row.params ? Object.keys(row.params).length : 0;
        },
        {
          id: 'inputCount',
          header: 'Inputs',
          cell: inputsCell,
          meta: {
            width: '90px',
            tdStyles: {
              overflow: 'visible',
            },
          },
        },
      ),
      columnHelper.accessor((row) => row, {
        id: 'status',
        header: 'Status',
        cell: statusCell,
        meta: {
          width: '150px',
          tdStyles: {
            overflow: 'visible',
          },
        },
      }),
      columnHelper.accessor(
        (row) => {
          const accessObj = new AccessManager(row.id, this.props.accessesByItemId, this.context.user);
          return accessObj.isShared() ? accessObj.bigListSharedColumnAvatars().length : 0;
        },
        {
          id: 'user_id',
          header: 'Share Status',
          cell: (info) => {
            const cellInfo = ListShareCellFactory(
              this.props.accessesByItemId,
              this.showAccessModal,
              this.context.user,
              32,
            )(info);
            return cellInfo.cell;
          },
          meta: {
            width: '150px',
          },
        },
      ),
      columnHelper.accessor('creator_user_id', {
        id: 'creator_id',
        header: 'Generated By',
        cell: generatedByCell,
        meta: {
          width: '150px',
          tdStyles: {
            overflow: 'visible',
          },
        },
      }),
      columnHelper.accessor((row) => moment(row.updated_on), {
        id: 'dateGenerated',
        header: 'Generated',
        cell: generatedCell,
        meta: {
          width: '150px',
          tdStyles: {
            overflow: 'visible',
          },
        },
      }),
      columnHelper.accessor((row) => row, {
        id: 'actions',
        header: '',
        cell: actionsCell,
        meta: {
          width: '50px',
          tdStyles: {
            overflow: 'visible',
          },
        },
      }),
    ];

    const bulkRemoveContentToast = (deleted) => {
      toastBanner(
        <Banner bannerType="success" text={`Succesfully deleted ${Pluralize('item', deleted.length, true)}`} />,
      );
    };

    const bulkDeletePresentations = (selectedItemIds) => {
      API.post(
        '/presentations/bulk_delete/',
        { presentation_ids: selectedItemIds },
        (response) => {
          bulkRemoveContentToast(response.data.deleted);
          this.props.onPresentationBulkDelete(response.data.deleted);
          this.props.setDeletedItems(selectedItemIds.map((stringId) => parseInt(stringId)));
          this.setState({
            title:
              this.props.pagination.totalCount - response.data.deleted.length === 0
                ? ''
                : `${this.props.pagination.totalCount - response.data.deleted.length} Generated`,
          });
        },
        (response) => console.warn(response), // eslint-disable-line no-console
      );
    };

    const bulkActionComponents = [
      (props) => (
        <BulkDeleteButton
          key="delete"
          selectedItemIds={props.selectedItems.map((presentation) => presentation.id)}
          bulkDeletePresentations={bulkDeletePresentations}
        />
      ),
    ];
    let filterTags = this.renderFilterIndicators();

    return (
      <div>
        {this.props.isBulk && (
          <Container className="is-fluid mtl mbm plm prl">
            <Heading size={2}>
              <Level>
                <Level.Side>
                  <Level.Item>Bulk Generated</Level.Item>
                </Level.Side>
                {!isEmpty(this.props.presentations[0].pdf) && (
                  <Level.Side align="right">
                    <Level.Item>
                      <PdfDownloadButton
                        onClick={() => pdf.downloadBulkPdf(this.props.bulkPresentationId)}
                        text="Download PDFs"
                      />
                    </Level.Item>
                  </Level.Side>
                )}
              </Level>
            </Heading>
          </Container>
        )}
        <div className="mtm">{map(filterTags, (tag) => tag)}</div>
        <PresentationPopulatingList
          entities={this.props.presentations}
          setSearchHits={this.props.setSearchHits}
          columns={columns}
          title={this.state.title}
          buttonClass="is-secondary"
          scrollInElement={false}
          loading={this.props.isPaginating}
          pagination={this.props.pagination}
          fetchItems={this.fetchItems}
          searchState={this.props.searchState}
          searchAttributes={this.props.searchAttributes}
          hideTopPagination={true}
          onRowClick={this.onRowClick}
          isSearching={this.props.isSearching}
          displaySortDropdown={true}
          displayFilterHeader={true}
          showFilterButton={false}
          entityType="presentation"
          filterPlaceholder="Find Content"
          bulkActionComponents={bulkActionComponents}
          accessesByItemId={this.props.accessesByItemId}
          updateIsFetchingPresentation={this.props.updateIsFetchingPresentation}
          deletedItems={this.props.deletedItems}
        />
        <AccessModal
          accesses={new AccessManager(this.state.currentItem.id, this.props.accessesByItemId, this.context.user)}
          item={this.state.currentItem}
          show={this.props.ui.modal?.name === 'accessModal'}
        />
      </div>
    );
  }

  onRowClick = (presentationId) => {
    this.setState({ redirectToPresentationId: presentationId });
  };

  fetchItems = (page, sort = null) => {
    const offset = page * Constants.PAGE_SIZE;
    this.props.fetchItems(offset, Constants.PAGE_SIZE, sort);
  };

  renderFilterIndicators = () => {
    let filterIndicators = [];
    if (this.props.searchState.refinementList) {
      forEach(this.props.searchState.refinementList, (v, k) => {
        const originalKey = k;
        if (k.includes('params.')) {
          k = k.replace('params.', '');
          const input = find(this.props.inputs.param_keys, { name: k });
          if (input) {
            k = input.display_name;
          }
        } else {
          k = this.props.searchAttributes[k];
        }
        if (Array.isArray(v)) {
          forEach(v, (_v) => {
            if (_v) {
              filterIndicators.push(
                <Tag
                  key={_v}
                  label={`${k}: ${_v}`}
                  onClose={() => this.cancelFilter({ key: k, value: _v, originalKey })}
                />,
              );
            }
          });
        } else {
          if (v) {
            filterIndicators.push(
              <Tag key={v} label={`${k}: ${v}`} onClose={() => this.cancelFilter({ key: k, value: v, originalKey })} />,
            );
          }
        }
      });
    }
    if (this.props.searchState.updated_on && this.props.searchState.updated_on[0] !== null) {
      const daterange = `${moment.unix(this.props.searchState.updated_on[0]).format(this.state.dateFormat)}-${moment
        .unix(this.props.searchState.updated_on[1])
        .format(this.state.dateFormat)}`;
      if (daterange) {
        filterIndicators.push(
          <Tag
            key={daterange}
            label={`Date Generated: ${daterange}`}
            onClose={() => this.cancelFilter('updated_on')}
          />,
        );
      }
    }
    return filterIndicators;
  };

  cancelFilter = (refinement) => {
    let newState = this.props.searchState;
    newState['cancelledFilter'] = refinement;
    if (refinement === 'updated_on') {
      delete newState.updated_on;
    } else {
      pull(newState.refinementList[refinement.originalKey], refinement.value);
      if (newState.refinementList[refinement.originalKey].length === 0) {
        delete newState.refinementList[refinement.originalKey];
      }
    }
    this.props.onSearchStateChange(newState);
  };

  showAccessModal = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ currentItem: item });
    this.props.openModal('accessModal');
  };

  getUserById = (id) => {
    if (this.state.users) {
      return this.state.users.find((user) => user.id === id);
    } else {
      return null;
    }
  };
}

PresentationsList.propTypes = {
  isBulk: PropTypes.bool,
  bulkPresentationId: PropTypes.any,
  initialPresentations: PropTypes.array,
  presentations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      pdf: PropTypes.any,
    }),
  ),
  setSearchHits: PropTypes.func,
  searchHits: PropTypes.array,
  fetchItems: PropTypes.func,
  nbHits: PropTypes.any,
  onPresentationDelete: PropTypes.func,
  inputs: PropTypes.object,
  isPaginating: PropTypes.bool,
  isSearching: PropTypes.bool,
  onPresentationBulkDelete: PropTypes.func,
  onPresentationSelect: PropTypes.func,
  onPresentationRegenerate: PropTypes.func,
  onSearchStateChange: PropTypes.func,
  pagination: PropTypes.object,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
  match: PropTypes.any,
  accessesByItemId: PropTypes.object,
  onRegenerateClick: PropTypes.func,
  enableProducerPresentationsTab: PropTypes.bool,
  openModal: PropTypes.func,
  ui: PropTypes.object,
  updateIsFetchingPresentation: PropTypes.func,
  deletedItems: PropTypes.array,
  setDeletedItems: PropTypes.func,
};

PresentationsList.contextType = UserContext;

const PresentationsListWrapper = (props) => {
  // When the itemIds argument is empty, useAccesses will fetch *all* accesses for
  // the given type. We don't want that to happen for presentations since there could be a ton.
  // Setting the type argument to null will disable the fetch.
  const { data: accessesByItemId } = useAccesses(
    props.presentations?.length > 0 ? 'presentation' : null,
    props.searchHits?.length && props.isSearching
      ? props.searchHits.map((p) => p.id)
      : props.presentations.map((p) => p.id),
  );
  return <PresentationsList {...props} accessesByItemId={accessesByItemId} />;
};
PresentationsListWrapper.propTypes = {
  presentations: PresentationsList.propTypes.presentations,
  searchHits: PresentationsList.propTypes.searchHits,
  isSearching: PresentationsList.propTypes.isSearching,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(connectStats(PresentationsListWrapper));

function PresentationStatusCell({ presentation, warnings, errors }) {
  let cellContent;
  let dropdownContent;

  if (errors.length > 0 || presentation.status === 'error') {
    cellContent = (
      <span className="presentation-status-cell">
        <div className="indicator error mls mrs"></div>
        Failed
      </span>
    );
    dropdownContent = (
      <span className="presentation-status-cell">
        <div className="indicator error mls mrs"></div>
        Failed while generating
      </span>
    );
  } else if (warnings.length > 0) {
    cellContent = (
      <span className="presentation-status-cell">
        <div className="indicator warning mls mrs"></div>
        Generated
      </span>
    );
    dropdownContent = (
      <span className="presentation-status-cell">
        <div className="indicator warning mls mrs"></div>
        {Pluralize('Warning', warnings.length, true)} while generating
      </span>
    );
  } else if (presentation.status === 'failed condition') {
    cellContent = (
      <span className="presentation-status-cell">
        <div className="indicator failed-condition mls mrs"></div>
        Not Generated
      </span>
    );
    dropdownContent = (
      <span className="presentation-status-cell">
        <div className="indicator failed-condition mls mrs"></div>
        Generation conditions not met
      </span>
    );
  } else if (presentation.status === 'done') {
    cellContent = (
      <span className="presentation-status-cell">
        <div className="indicator done mls mrs"></div>
        Generated
      </span>
    );
    dropdownContent = (
      <span className="presentation-status-cell">
        <div className="indicator done mls mrs"></div>
        No errors while generating
      </span>
    );
  } else {
    cellContent = (
      <span className="presentation-status-cell">
        <div className="mrxs flex items-center">
          <Icon name="clock" size={20} />
        </div>
        Generating
      </span>
    );
    dropdownContent = (
      <span className="presentation-status-cell">
        <div className="mrxs flex items-center">
          <Icon name="clock" size={20} />
        </div>
        {presentation.status}
      </span>
    );
  }

  return (
    <span className="is-flex overflow-visible">
      <DropdownMenu dropdownTrigger={cellContent} isRight={false} className="centered-text">
        <span className="dropdown-item">{dropdownContent}</span>
      </DropdownMenu>
    </span>
  );
}

PresentationStatusCell.propTypes = {
  presentation: PropTypes.object,
  warnings: PropTypes.array,
  errors: PropTypes.array,
};

const PresentationSlidesCell = ({ row }) => {
  const slideIds = row.original.slide_ids || [];
  const emptyIds = isEmpty(Object.keys(slideIds));

  const isSlideType = Constants.TEMPLATE_SOURCE_TYPES.PRESENTATION_TYPES.includes(row.original.presentation_type);
  const cellContent = <span>{isSlideType ? row.original.slide_count : 'N/A'}</span>;

  const { data: thumbnails } = useBulkTemplateThumbnails(row.original.template?.id, 4);

  let body = (
    <Columns className="dropdown-columns pam">
      {!emptyIds ? (
        map(take(slideIds, 4), (slideId, index) => {
          let fetchStatus = { fetchState: 'fetching' };
          if (thumbnails) {
            if (thumbnails.length > index) {
              fetchStatus = {
                data: thumbnails[index],
                fetchState: 'fetched',
                errorMessage: '',
              };
            } else {
              return null;
            }
          }
          if (index < 3) {
            return (
              <Columns.Column size="half" className="dropdown-item" key={index}>
                <AsyncLoadImage
                  isBulk={true}
                  imageClass="async-rounded has-light-gray-border"
                  height="80px"
                  fetchUrl={'unused'}
                  fetchStatus={fetchStatus}
                />
              </Columns.Column>
            );
          } else {
            return (
              <Columns.Column size="half" className="dropdown-item" key={index}>
                <div className="dropdown-image-with-overlay">
                  <AsyncLoadImage
                    isBulk={true}
                    imageClass="async-rounded"
                    height="80px"
                    fetchUrl={'unused'}
                    fetchStatus={fetchStatus}
                  />
                  <div className="after"></div>
                  <span className="after-text">+{row.original.slide_count - 3}</span>
                </div>
              </Columns.Column>
            );
          }
        }).filter((el) => !!el)
      ) : (
        <span className="dropdown-item">No Slides</span>
      )}
    </Columns>
  );
  return (
    <span className="is-flex overflow-visible">
      {isSlideType ? (
        <DropdownMenu dropdownTrigger={cellContent} isRight={false}>
          {body}
        </DropdownMenu>
      ) : (
        cellContent
      )}
    </span>
  );
};
PresentationSlidesCell.propTypes = {
  row: PropTypes.object,
};

/** Transform minimal data into full presentation records. */
const useFullPresentations = (presentations) => {
  const idsToPopulate = presentations
    .filter((presentation) => !presentation.logs && !presentation.slide_ids)
    .map((presentation) => presentation.id);

  const results = usePresentations(idsToPopulate);

  let populated = presentations;
  if (idsToPopulate.length > 0) {
    populated = presentations.map((unpopulated) => results[unpopulated.id]?.data || unpopulated);
  }
  return populated;
};

const PresentationPopulatingList = ({ entities, setEntities, ...others }) => {
  const presentations = useFullPresentations(entities);
  return <ListWithFilterHeaderAndBulkActions entities={presentations} setEntities={setEntities} {...others} />;
};
PresentationPopulatingList.propTypes = {
  entities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ),
  setEntities: PropTypes.func,
};

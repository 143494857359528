import React from 'react';
import Pluralize from 'pluralize';
import PropTypes from 'prop-types';
import Constants from 'components/Constants';
import DropdownMenu from 'components/lib/DropdownMenu';
import Icon from 'components/lib/Icon';
function EmailStatusCell({ presentation, warnings, errors, leftMargin }) {
  let cellContent;
  let dropdownContent;
  const undeliveredStatuses = [Constants.SendgridStatuses.BOUNCE, Constants.SendgridStatuses.DROPPED];
  if (presentation.sendgrid_data?.delivery_status === Constants.SendgridStatuses.DELIVERED) {
    cellContent = (
      <span className="presentation-status-cell">
        <div className={`indicator done ${leftMargin} mrs`}></div>
        Delivered
      </span>
    );
    dropdownContent = (
      <span className="presentation-status-cell">
        <div className={`indicator done ${leftMargin} mrs`}></div>
        Email has been delivered
      </span>
    );
  } else if (errors.length && presentation.sendgrid_data?.delivery_status === Constants.SendgridStatuses.UNSENT) {
    cellContent = (
      <span className="presentation-status-cell">
        <div className={`indicator error ${leftMargin} mrs`}></div>
        Not Delivered
      </span>
    );
    dropdownContent = (
      <span className="presentation-status-cell">
        <div className={`indicator error ${leftMargin} mrs`}></div>
        Failed while generating
      </span>
    );
  } else if (undeliveredStatuses.includes(presentation.sendgrid_data?.delivery_status)) {
    cellContent = (
      <span className="presentation-status-cell">
        <div className={`indicator error ${leftMargin} mrs`}></div>
        Not Delivered
      </span>
    );
    dropdownContent = (
      <span className="presentation-status-cell">
        <div className={`indicator error ${leftMargin} mrs`}></div>
        {`Failed to send - ${presentation.sendgrid_data?.delivery_status}`}
      </span>
    );
  } else if (warnings.length && presentation.sendgrid_data?.delivery_status === Constants.SendgridStatuses.UNSENT) {
    cellContent = (
      <span className="presentation-status-cell">
        <div className={`indicator warning ${leftMargin} mrs`}></div>
        Not Delivered
      </span>
    );
    dropdownContent = (
      <span className="presentation-status-cell">
        <div className={`indicator warning ${leftMargin} mrs`}></div>
        {Pluralize('Warning', warnings.length, true)} while generating
      </span>
    );
  } else if (
    presentation.sendgrid_data?.delivery_status === Constants.SendgridStatuses.UNSENT &&
    presentation.status === 'failed condition'
  ) {
    cellContent = (
      <span className="presentation-status-cell">
        <div className={`indicator failed-condition ${leftMargin} mrs`}></div>
        Not Delivered
      </span>
    );
    dropdownContent = (
      <span className="presentation-status-cell">
        <div className={`indicator failed-condition ${leftMargin} mrs`}></div>
        Send conditions not met
      </span>
    );
  } else if (presentation.sendgrid_data?.delivery_status === Constants.SendgridStatuses.UNSENT) {
    cellContent = (
      <span className="presentation-status-cell">
        <div className={`indicator unsent_error ${leftMargin} mrs`}></div>
        Not Delivered
      </span>
    );
    dropdownContent = (
      <span className="presentation-status-cell">
        <div className={`indicator unsent_error ${leftMargin} mrs`}></div>
        {presentation.sendgrid_data?.delivery_status_reason || 'Not Delivered'}
      </span>
    );
  } else {
    cellContent = (
      <span className="presentation-status-cell">
        <div className="mrxs flex items-center">
          <Icon name="clock" size={20} />
        </div>
        Generating
      </span>
    );
    dropdownContent = (
      <span className="presentation-status-cell">
        <div className="mrxs flex items-center">
          <Icon name="clock" size={20} />
        </div>
        Email is generating
      </span>
    );
  }
  return (
    <DropdownMenu dropdownTrigger={cellContent} isRight={false}>
      <span className="dropdown-item">{dropdownContent}</span>
    </DropdownMenu>
  );
}

export default EmailStatusCell;

EmailStatusCell.propTypes = {
  presentation: PropTypes.object,
  warnings: PropTypes.array,
  errors: PropTypes.array,
  leftMargin: PropTypes.string,
};
